import Image1 from "../Images/Image1.jpg";
import Image2 from "../Images/Image2.jpg";
import Image3 from "../Images/Image3.jpg";

export const images = [
    {title: "Image 1", 
     subtitle: "image 1", 
     img: Image1},
    {title: "Image 2", 
     subtitle: "image 2", 
     img: Image2},
    {title: "Image 3", 
     subtitle: "image 3", 
     img: Image3},
]